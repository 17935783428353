import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";

// 集成管理
export const SYSTEMINTEGRATION = [
    {
        path: '/SYSTEMINTEGRATION',
        component: Layout,
        redirect: '/SYSTEMINTEGRATION/integrationManagement/',
        name: '集成管理',
        menuName: '集成管理',
        meta: {
            title: "集成管理",
            icon: "el-icon-menu",
        },
        children: [
            {
                path: 'integrationManagement',
                component: () => import('@/views/SystemIntegration/system'),
                name: '系统集成',
                menuName: '系统集成',
                meta: {title: "集成管理"}
            },
        ]
    },
];

// 系统设置
export const SYSTEMSETTING = [
    {
        path: '/SYSTEMSETTING',
        component: Layout,
        redirect: '/SYSTEMSETTING/systemSetting/',
        name: '系统设置',
        menuName: '系统设置',
        meta: {
            title: "系统设置",
            icon: "el-icon-s-grid",
        },
        children: [
            {
                path: 'systemSetting',
                component: () => import('@/views/DSNREVIEW/systemSetting'),
                name: '系统设置',
                menuName: '系统设置',
                meta: {title: "系统设置"}
            },
        ]
    },
];

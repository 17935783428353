import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const SYMBOLNE = [
  {
    path: '/SYMBOLNE',
    component: Layout,
    redirect: '/SYMBOLNE/symbolFormList/',
    name: 'Capture符号库s',
    meta: {
      title: "Capture符号库",
      icon: "fa fa-file-text-o",
    },
    children: [
      {
        path: 'symbolFormList',
        name: 'Capture符号列表s',
        meta: {
          title: "Capture符号列表",
          activeMenu:"/SYMBOLNEW"
        },
        component: () => import('@/views/TheSymbolLibrary/SYMBOLNEW/symbolFormList'),
      },
    ]
  },

  {
    path: '/CONCEPTLI',
    component: Layout,
    redirect: '/CONCEPTLI/symbolicLibrary/',
    name: 'Concept符号库s',
    meta: {
      title: "Concept符号库",
      icon: "fa fa-file-code-o",
    },
    children: [
      {
        path: 'symbolicLibrary',
        name: 'Concept符号列表s',
        hidden:true,
        meta: {
          title: "Concept符号列表",
          activeMenu:"/CONCEPTLIB"
        },
        component: () => import('@/views/TheSymbolLibrary/symbolicLibrary/conceptlibrary'),
      },
    ]
  },

];
export const SYMBOLNEW = [

  {
    path: '/SYMBOLNEW',
    component: Layout,
    redirect: '/SYMBOLNEW/symbolFormList/',
    name: 'Capture符号库',
    menuName:'Capture符号库',
    meta: {
      title: "Capture符号库",
      icon: "fa fa-file-text-o",
    },
    children: [
      {
        path: 'symbolFormList',
        name: 'Capture符号列表',
        menuName:'Capture符号列表',
        hidden:true,
        meta: {
          title: "符号列表",
          activeMenu:"/SYMBOLNEW"
        },
        component: () => import('@/views/TheSymbolLibrary/SYMBOLNEW/symbolFormList'),
      },
    ]
  },

  {
    path: '/CONCEPTLI',
    component: Layout,
    redirect: '/CONCEPTLI/symbolicLibrary/',
    name: 'Concept符号库',
    menuName:'Concept符号库',
    meta: {
      title: "Concept符号库",
      icon: "fa fa-file-code-o",
    },
    children: [
    {
      path: 'symbolicLibrary',
      name: 'Concept符号列表s',
      // hidden:true,
      meta: {
        title: "Concept符号列表",
        activeMenu:"/CONCEPTLIB"
      },
      component: () => import('@/views/TheSymbolLibrary/symbolicLibrary/conceptlibrary'),
    },
    ]
  },

];

import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";


export const BASEUSE = [

];
export const BASEUSER = [
  {
    path: '/BASEUSER',
    component: Layout,
    redirect: '/BASEUSER/myAppConfig/',
    name: '组织架构',
    menuName: '组织架构',
    meta: {
      title: "组织架构",
      icon: "fa fa-cogs",
    },
    children: [
      {
        path: 'employeesManage',
        component: () => import('@/views/organizationalStructure/baseuser/myConfiguration/employeesManage'),
        name: '员工管理',
        menuName: '员工管理',
        meta: {
          title: "员工管理",
        },
      },
      {
        path: 'webRolePermiss',
        component: () => import('@/views/organizationalStructure/baseuser/webRole/webRolePermiss'),
        name: '角色管理',
        menuName: '角色管理',
        meta: {
          title: "角色管理",
        },
        icon: 'zonghe'
      },
      {
        path: 'webUserRolePermiss',
        component: () => import('@/views/organizationalStructure/baseuser/webRole/webUserRolePermiss'),
        name: '人员角色',
        menuName: '人员角色',
        meta: {
          title: "人员角色",
        },
        icon: 'zonghe'
      },

    ]
  }
];

import Layout from "@/views/layout/index";

export const PRODUCTDESIGNMANAGE = [
    {
        path: '/PRODUCT',
        component: Layout,
        redirect: '/PRODUCT/ProductManage/',
        name: '产品设计管理',
        menuName: '产品设计管理',
        meta: {
            title: "产品设计管理",
            icon: "el-icon-folder-opened",
        },
        children: [
            {
                path: 'ProductManage',
                component: () => import('@/views/productDesignManage/index'),
                name: '产品设计管理',
                menuName: '产品设计管理',
                meta: {title: "产品设计管理"},
            },
            {
                path: 'ComponentStatistics',
                component: () => import('@/views/productDesignManage/componentStatistics'),
                name: '元器件统计',
                menuName: '元器件统计',
                meta: {title: "元器件统计"},
            },
        ]
    },
];

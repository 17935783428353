import Layout from "@/views/layout/index";
import MainLayout from "@/views/layout/MainLayout";

export const ENCAPLI = [
  {
    path: '/ENCAPLIBS',
    component: Layout,
    redirect: '/ENCAPLIBS/packageForm/',
    name: '封装库S',
    children: [
      {
        path: 'packageForm',
        component: MainLayout,
        name: '封装库列表S',
        icon: 'zonghe',
        redirect: '/ENCAPLIBS/packageForm/morePackageForm',
        children: [
          { path: 'morePackageForm', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/morePackageForm'), name: '符号库更多', icon: 'zonghe',meta:{activeMenu:"/ENCAPLIB"}},
          { path: 'moreSchematicSymbol', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/moreSchematicSymbol'), name: '封装详情', icon: 'zonghe',meta:{activeMenu:"/ENCAPLIB"} },
          { path: 'packageTypeImport', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/packageTypeImport'), name: '批量上传', icon: 'zonghe',meta:{activeMenu:"/ENCAPLIB"} },
          { path: 'packageTypeImpoartshow', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/packageTypeImpoartshow'), name: '导入预览', icon: 'zonghe',meta:{activeMenu:"/ENCAPLIB"} },
        ]
      },
    ]
  },
];
export const ENCAPLIB = [
  {
    path: '/ENCAPLIB',
    component: Layout,
    redirect: '/ENCAPLIB/pcbManager/',
    name: '封装库',
    menuName:'封装库',
    meta: {
      title: "封装库",
      icon: "fa fa-object-group",
    },
    children: [
      // { path: 'pcbManager', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/pcbManager'), name: 'PCB封装管理',menuName:'PCB封装管理',meta:{title:"PCB封装管理"} },
      // { path: 'packageList', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/packageList'), name: '封装列表',menuName:'封装列表',meta:{title:"封装列表"} },
      { path: 'packageFileUpload', component:  () => import('@/views/EncapsulationLibrary/FILELIB/packageFileUpload'),hidden:true, name: '封装库列表',menuName:'封装库列表',meta:{title:"封装库列表",activeMenu:"/ENCAPLIB"} },
    ]
  },
];

export const THREELIB = [
  {
    path: '/THREED',
    component: Layout,
    redirect: '/THREED/pcbManager/',
    name: '三维库',
    menuName:'三维库',
    meta: {
      title: "三维库",
      icon: "fa fa-cube",
    },
    children: [
      { path: 'threeDimensionalLib', component:  () => import('@/views/EncapsulationLibrary/manager/packageForm/threeDimensionalLib'),hidden:true, name: '三维库列表',menuName:'三维库列表',meta:{title:"三维库列表",activeMenu:"/THREED"} },
    ]
  },
];

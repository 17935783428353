import request from '@/utils/request'

/**
 * 类目树
 */
export function readCategoryTree(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/read/anon/category',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 修改类目树
 */
export function updateCategory(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 排序类目树
 */
export function sortCategory(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/sort',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 添加类目树
 */
export function deleteCategory(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取所有属性
 */
export function readPropertyList(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/property/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除节点属性
 */
export function deleteHaveProperty(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/property/relation/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取类目下的物品
 */
export function readCategoryGoods(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoods/read/anon/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取类目下的物品列表表头字段
 */
export function readCategoryGoodshead(data) {

    return request({
        url: '/api/v1/componentPublicPropertyConf/get/anon/list_field/field',
        method: 'get',
        params: data,
    });
}

/**
 * 获取类目下的物品详情表头字段
 */
export function readCategoryGoodsdetailhead(data) {

    return request({
        url: '/api/v1/componentPublicPropertyConf/get/anon/detail_field/field',
        method: 'get',
        params: data
    });
}

/**
 * 获取类目下的物品高级搜索字段
 */
export function readCategoryGoodssearchhead(data) {

    return request({
        url: '/api/v1/componentPublicPropertyConf/get/anon/search_field/field',
        method: 'get',
        params: data
    });
}


/**
 * 获取搜索模板
 */
export function getSearchtemplate(data) {

    return request({
        url: '/cql/v1/tables/component_goods/template',
        method: 'get',
        params: data
    });
}


/**
 * 搜索查询数据接口
 */
export function searchData(data) {

    return request({
        url: `/cql/v1/?${data}`,
        method: 'get',
    });
}


/**
 * 物品详情接口
 */
export function readCategoryGoodsDetail(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/anon/read/part_by_pn',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 物品详情中生命周期接口
 */
export function readCategoryGoodsDetaillife(data) {

    //const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/version/anon/life/cycle/' + data,
        method: 'get',
        //data: params,
        // headers: {
        //   'content-type': 'application/json'
        // }
    });
}

/**
 * 物品详情表格中价格和库存接口
 */
export function readCategoryGoodsDetailprice(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentSuppliersPriceLog/anon/read/list_by_supplier',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 物品详情表格接口
 */
export function readCategoryGoodsDetailtable(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/suppliers/anon/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 物品分页接口
 */
export function readCategoryGoodsPage(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoods/read/anon/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 物品分页接口
 */
export function readCategoryketidai(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/anon/read/replace_part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 器件属性信息查询接口（维护的那些：优选等级）
 */
export function getyouxuanattr(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/anon/get_pre_ranking',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取制造商和供应商关系表接口
 */
export function getzzsgys(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/anon/get_manufacturers',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 首页数据手册列表初始化
 */
export function initDataManual(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 相似器件接口
 */
export function sameparts(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/anon/read/similitude_Part',
        method: 'post',
        data: params,
        timeout: 1000 * 60,
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 器件生命周期统计
 */
export function statisticalLifeCycle(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentLifeCycleLog/anon/read/linechart',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 获取原理图符号svg
export function yuanlitusvg(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/anon/get/svg',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 根据路径获取封装图形接口
export function getPackageImageByPath(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentOlbLibrary/get_dra',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}


// concept图形显示
export function getSvgByConcept(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentConceptLibrary/anon/get/svg',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查询3d文件列表的接口
 */
export function get3dPage(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/symbol_3d_library/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 供应商客户分页
 */
export function readScmSupplierPage(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 供应商客户列表
 */
export function readScmSupplierList(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/get_relation',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 检查是否有错误数据
 */
export function lookCheckScmSupplier(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/retrieval/repetition',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 检查是否有错误数据
 */
export function updateAllScmSupplier(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/insertAll',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 修改供应商客户
 */
export function updateScmSupplier(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除供应商客户
 */
export function deleteScmSupplier(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturersAndSuppliers/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料分页
 */
export function readManufacturersPage(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料全部
 */
export function readManufacturersList(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料同代理供应商关系接口
 */
export function readManufacturersSuppliersList(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料新增
 */
export function updateManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料修改
 */
export function auditManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/modify',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入制造商物料修改
 */
export function importManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/import/lookSure',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入制造商物料状态修改
 */
export function importStatusManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/import/status/sure',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入制造商物料查询不合格是那几个
 */
export function lookCheckManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/import/look/check',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入制造商物料查询状态不合格是那几个
 */
export function lookCheckStatusManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/import/status/check',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料是否被代理
 */
export function lookManufacturersPartIsUse(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/is/use',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商物料删除
 */
export function deleteManufacturers(data) {
    const params = data;
    return request({
        url: '/api/v1/manufacturers/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 代理商代理物料
 */
export function readSuppliersAttrPage(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 代理商代理物料
 */
export function readSuppliersAttrList(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 代理商代理物料
 */
export function updateSuppliersAttr(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 代理商代理物料
 */
export function deleteSuppliersAttr(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入供应商物料修改
 */
export function lookCheckSuppliers(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/import/look/validate',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入供应商物料修改
 */
export function importSuppliers(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/import/look/sure',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 供应商价格更新导出模板：
 /export/format/price
 供应商价格更新导入预览：
 /import/look/price
 供应商价格更新导入预览检查：
 /import/price/look/validate 参数：List<ComponentSuppliers>
 供应商价格更新导入预览确定：
 /import/price/look/sure 参数：List<ComponentSuppliers>

 */

/**
 * 批量导入供应商物料修改
 */
export function lookCheckPriceSuppliers(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/import/price/look/validate',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量导入供应商物料修改
 */
export function importAllPriceSuppliers(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/import/price/look/sure',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 元器件导入检查是否有错误数据
 */
export function lookProductCheckData(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/check/data',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 5,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 元器件批量保存
 */
export function updataProductByList(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/import',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 30,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 制造商列表
 */
export function manufacturersList(data) {
    const params = data;
    return request({
        url: '/api/v1/suppliers/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 添加属性属性结构
 */
export function addPropertyTree(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/property/update/template',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 添加属性属性结构
 */
export function copyPropertyTree(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/property/update/template/copy',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看节点有属性
 */
export function readHavePropertyList(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/property/relation/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 修改节点属性
 */
export function updateHaveProperty(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/property/relation/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量修改节点属性
 */
export function updateAllHaveProperty(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/property/relation/update/all',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取所有类目可选择的图片
 */
export function readCategoryPictureList(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elibm_template_picture/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除类目可选择的图片
 */
export function deleteCategoryPicture(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elibm_template_picture/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取模板列表
 */
export function queryTemplateList(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentApprovalNodePropertyConf/read/list/templatename',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 根据模板获取详情
 */
export function queryTemplateDetailByName(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentApprovalNodePropertyConf/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 更新模板的详情
 */
export function updateTemplateDetail(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentApprovalNodePropertyConf/updateall',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除模板更加名称
 */
export function deletebyTemplateName(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentApprovalNodePropertyConf/deletebyTemplateName',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 属性优先级保存接口
 */
export function attrsaveupdown(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/property/relation/update_sort',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取模板下所有属性
 */
export function readPropertyClassList(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/property/read/tree/property',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取所有属性属性结构
 */
export function readPropertyTree(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/property/read/tree',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看模块下已经开启的工具
 */
export function readUseToolMyList(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/sysModelTool/read/list/use',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 元器件批量导出
 */
export function partsExport(data) {
    const params = data;
    return request({
        url: '/api/v1/goods/batchManagement/export',
        method: 'post',
        data: params,
        responseType: 'blob',
    });
}

/**
 * 元器件导出 new
 */
export function exportComponentExcel(params) {
    return request({
        url: '/api/v1/exportComponentExcel',
        method: 'post',
        data: JSON.stringify(params),
        responseType: 'blob',
        headers: {
            'content-type': 'application/json'
        },
        timeout: 1000 * 60 * 15,
    });
}

/**
 * 查询导出元器件报表进度
 */
export function getComponentExcelProgress(params) {
    return request({
        url: '/api/v1/exportComponentExcel/progress',
        method: 'get',
        params,
        headers: {
            'content-type': 'application/json'
        },
    });
}

/**d
 * 提交审批认领单列表的订单
 */
export function startProcessOrder(data) {

    const params = JSON.stringify(data);
    return request({
        url: 'api/v1/elib/process_approve/start_process_instance',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册保存接口
 */
export function savedatasheet(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看器件的历史记录列表
 */
export function readPartHistory(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/version/history_list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 建库详情
 */
export function readAddApplyDetail(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/data/read/detail',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**d
 * 订单详情
 */
export function readOrderDetail(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/order/read/detail_by_id',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈添加修改接口
 */
export function wrongbackupdate(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsFeedback/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈类型修改添加接口
 */
export function wrongbacktypeedit(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentFeedbackType/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈类型删除接口
 */
export function wrongbacktypedel(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentFeedbackType/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈类型接口
 */
export function wrongbacktype(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentFeedbackType/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈删除接口
 */
export function wrongbackdel(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsFeedback/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 错误反馈列表接口
 */
export function wrongback(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsFeedback/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 认领修改公海的任务
 */
export function updateReviseApplyStatus(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/update/status',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 改库
 */
export function updateReviseApply(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/data/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 物品修改新旧值
 */
export function updateProjectReviseApply(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 更新数据手册
 */
export function updateDataBook(data) {

    // const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/update',
        method: 'post',
        data,
        timeout: 1000 * 60 * 3,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 改库后确认入库
 */
export function updateConfirmationOfWarehousing(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/confirmation_of_warehousing',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量跟新器件生命周期
 */
export function updateAllLifeCycle(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentLifeCycleLog/updateAll',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 建库
 */
export function updateAddApply(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/data/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 导入定时器
 */
export function timingDevice(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/goods/anon/timing_device',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 点击分类展示详情接口
export function symbledetail(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentConceptLibrary/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册查询接口
 */
export function searchdatasheet(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 保存评价
 */
export function saveevaluation(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsComment/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 器件生命周期分页
 */
export function readStatisticalLifeCyclePage(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentLifeCycleLog/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 物品修改公海列表
 */
export function readReviseApplyPage(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 修改库数据回显
 */
export function readReviseApplyMore(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/read/more',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 修改库数据回显
 */
export function readReviseApplyDetail(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/revise_apply/data/read/detail',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看版本历史记录的修改内容
 */
export function readPartContentOther(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/version/compare',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 查看版本历史记录的修改内容
 */
export function readPartContentHistory(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/version/history_detail',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取订单列表
 */
export function readOrderPage(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/order/list/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 常用元器件列表
export function readMycommonlyPage(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component_goods_invoking/get/common_goods',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**d
 * 获取大类下面的选项
 */
export function readComponentOlbLibrarySvgList(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentOlbLibrary/svg/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**d
 * 获取一级大类
 * 参数{"type":"1"} 标识 原理图
 * 2 标识 为封装符号
 */
export function readComponentOlbLibraryList(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentOlbLibrary/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 建库申请
 */
export function putInStorage(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/confirmation_of_warehousing	',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 批量更新新增excel入库接口
 */
export function newupdateexcel(data) {

    const params = data
    return request({
        url: '/api/v1/goods/batchManagement/import/batch/update',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 20,
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

/**
 * 批量导入新增excel入库接口
 */
export function newaddexcel(data) {

    const params = data
    return request({
        url: '/api/v1/goods/batchManagement/import/batch/insert',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 20,
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

/**
 * 数据手册导入检查是否有错误数据
 */
export function lookDataBookCheckData(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/manual/anon/upload/check_data',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 5,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 回收站列表接口
 */
export function huishoulist(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsRecycle//read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 回收站恢复接口
 */
export function huishouback(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsRecycle/recovery_part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

//获取上传文件之后预览数据的接口
export function getviewdata(data) {
    // const params = JSON.stringify(data);
    const params = data;//提交表单数据和上传文件
    return request({
        url: '/api/v1/public/excel/analysis',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

// 根据三级的id查名字
export function getleixingname(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/category/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });

}

/**
 * 获取excel批量提交时的可对照字段接口
 */
export function getexcelduizhao(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/goods/batchManagement/get/systemProperty',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 获取全部器件接口
 */
export function getallpart(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoods/read/anon/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 查询符号分类接口/不分页
export function getSymbolTypelist(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/edmSlibraryClass/read/list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**d
 * 末级节点下的所有属性 , 包括公共的和特殊的属性
 */
export function getLastCateProperty(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/get_param',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查询数据手册历史记录接口
 */
export function getDataBookHistory(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual_detail/anon/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 元器件可替代物料列表接口
 */
export function fungibleparts(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/anon/read/replace_part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 解析导入excel数据
 */
export function exceldate(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/product/get_excel_list',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 解析导入的数据手册excel数据
 */
export function exceldataBookData(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/anon/upload/get_data',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查看使用评价
 */
export function evaluationuse(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsComment/read/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 修改元器件可替代物料列表接口
 */
export function editfungibleparts(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/update_replace_part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册中删除回收站列表
 */
export function delsheetcollec(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/multdelete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除元器件可替代物料列表接口
 */
export function deletefungibleparts(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/delete_replace_part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除器件生命周期
 */
export function deleteLifeCycle(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentLifeCycleLog/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除评价
 */
export function deleteEvaluation(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoodsComment/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 删除数据手册
 */
export function deleteDataBook(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 删除元器件
 */
export function deleteComponentGoods(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/componentGoods/delete',
        method: 'delete',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 数据手册中回收站的列表
 */
export function datasheetcollec(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/recycle',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册上传
 */
export function dataBookUpload(data) {

    const params = data;//提交表单数据和上传文件
    return request({
        url: '/api/v1/elib/manual/manual_upload',
        method: 'post',
        data: params,
        timeout: 1000 * 60 * 3,
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

// 我的收藏元器件列表
export function collectionpartlist(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/component_goods_collect/get/page',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册中回收站重命名接口
 */
export function collecrename(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/restore/rename',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册中回收站恢复到历史记录接口
 */
export function collecrehistory(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/restore/sure',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 判断数据手册是否重复
 */
export function datasheetRepeat(data) {

    const params = JSON.stringify(data);
    return request({
        url: 'api/v1/manual-check-repeat',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 数据手册中回收站恢复接口
 */
export function collecrecover(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/manual/restore',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 建库申请
 */
export function buildLibApply(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/update/status',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 器件属性维护停用启用接口
 */
export function attrweihustop(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/component_property_setting/update_state',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查询器件属性维护数据接口
 */
export function attrweihusearch(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/component_property_setting/select_property_value',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 器件属性维护保存修改接口
 */
export function attrweihusave(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/component_property_setting/update_property',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 新增元器件可替代物料列表接口
 */
export function addfungibleparts(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/product/add_Replace_Part',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**d
 * 三合一接口第二部
 */
export function addWarehousingProcess(data) {

    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/elib/component/add_apply/warehousing/start/process',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 器件追溯图的接口
 */
export function Callgraphs(data) {
    const params = data;
    return request({
        url: '/api/v1/elib/component_goods_invoking/get/tree',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 批量更新对比预览
export function compareview(data) {
    //const params = JSON.stringify(data);
    const params = data;
    return request({
        url: '/api/v1/goods/batchManagement/import/batch/update/view',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

// 收藏元器件添加接口
export function collectionpartsave(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/component_goods_collect/update',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 收藏元器件删除接口
export function collectionpartdel(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/api/v1/component_goods_collect/delete',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 获取客户端列表
export function getClientList() {
    return request({
        url: '/api/v1/client/list'
    });
}

/**
 * 获取BOM分类
 */
export function readBOMCategory(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/deehow-BOM-Web/bom-category',
        method: 'get',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 修改BOM分类
 */
export function updateBOMCategory(data) {
    const params = JSON.stringify(data);
    return request({
        url: '/deehow-BOM-Web/update-bom-category',
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 修改BOM分类
 */
export function deleteBOMCategory(id) {
    // const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/bom-category/${id}`,
        method: 'delete',
        // data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * BOM文件上传
 */
export function uplaodBOMFile(params) {
    // const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/upload-bom/files`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

/**
 * BOM文件解析
 */
export function parseExcelFile(data) {
    const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/parse-excel-file`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * BOM其他文件上传
 */
export function uplaodOtherFile(params) {
    // const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/bom-card-other-file/upload`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'multipart/form-data'
        }
    })
}

/**
 * 其他文件分页
 */
export function readOtherFile(data) {
    const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/bom-card-other-file/list`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * BOM文件解析
 */
export function parseBOMFile(bomFileId, params) {
    // const params = JSON.stringify(data);
    return request({
        url: `/deehow-BOM-Web/parse-execl/${bomFileId}`,
        method: 'get',
        params: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 保存匹配的BOM文件
 */
export function saveMatchBOMFile(bomFileId, params) {
    console.log(params)
    const param = JSON.stringify(params.condition);
    return request({
        url: `/deehow-BOM-Web/match-execl/${bomFileId}?headerRow=${params.headerRow}&sheetName=${params.sheetName}`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 删除的BOM文件
 */
export function deleteBOMFile(bomFileId) {
    return request({
        url: `/deehow-BOM-Web/bom-file/${bomFileId}`,
        method: 'delete',
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 删除的BOM文件
 */
export function deleteBOMHistoryFile(bomFileId) {
    return request({
        url: `/deehow-BOM-Web/bom-history-file/${bomFileId}`,
        method: 'delete',
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 获取智能推荐接口
 */
export function smartBOMMatch(page, params) {
    const param = JSON.stringify(params);

    return request({
        url: `/deehow-BOM-Web/smart-match?num=${page.pageNum}&size=${page.pageSize}`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}
/**
 * 重新匹配
 */
export function restMatchBOM(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/batch-match-data`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 搜索库中器件
 *
 */
export function searchOldBomPart(page, params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/search?num=${page.pageNum}&size=${page.pageSize}`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 搜索库中器件(新)
 *
 */
export function searchBomPart(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/search/column`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 无匹配状态时，需要获取其所有数据
 * @param params
 * @returns {AxiosPromise}
 */
export function searchNoMatchData(params) {
    return request({
        url: `/deehow-BOM-Web/no-match?pageNum=${params.pageNum}&pageSize=${params.pageSize}`,
        method: 'post',
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 保存编辑后的bom
 *
 */
export function saveRecordBom(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/bom-card-records`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 保存编辑后的bom
 *
 */
export function readRecordBom(bomId) {
    return request({
        url: `/deehow-BOM-Web/bom-card-records/${bomId}`,
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 获取bom分类下的bom文件
 *
 */
export function readBomfile(cateId) {

    return request({
        url: `/deehow-BOM-Web/bom-file/${cateId}`,
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 获取bom分类下的bom文件
 *
 */
export function readBomPage(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/bom-file/page`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 获取bom分类下的bom文件
 *
 */
export function updateBom(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/bom-file/update`,
        method: 'post',
        data: param,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 搜索匹配的文件
 *
 */
export function searchFileByRecords(params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    return request({
        url: `/deehow-BOM-Web/search-card-records`,
        method: 'get',
        params: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 搜索匹配的文件
 *
 */
export function searchNewFile(params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    return request({
        // url: `/deehow-BOM-Web/search-new-card-records`,
        url: `/deehow-BOM-Web/search-records`,
        method: 'get',
        params: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 搜索匹配的文件
 *
 */
export function searchFileDetatil(id, params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    return request({
        url: `/deehow-BOM-Web/search-detail-data/${id}`,
        method: 'get',
        params: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 文件历史记录
 *
 */
export function readBomHistory(params, page) {
    // /search-card-records?content=CC1210KKX7R8BB226
    return request({
        url: `/deehow-BOM-Web/bom-card-file-history?num=${page.pageNum}&size=${page.pageSize}`,
        method: 'get',
        params: params,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 文件删除
 *
 */
export function deleteOtherFile(id, delHistory) {
    // /search-card-records?content=CC1210KKX7R8BB226
    let params = new FormData();
    params.append("delHistory", delHistory)
    return request({
        url: `/deehow-BOM-Web/bom-card-other-file/${id}`,
        method: 'delete',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}


/**
 * 添加列的时候获取所有数据的固定列
 *
 */
export function readRowColumn(params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/get_data/column`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 添加列的时候获取所有数据的固定列
 *
 */
export function getNumInstall(params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/count-num-install`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 获取完全匹配 , 属性不一致的
 *
 */
export function getAttributeMatch(params) {
    // /search-card-records?content=CC1210KKX7R8BB226
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/match-attribute`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 同步es数据
 *
 */
export function componentSync() {
    // /search-card-records?content=CC1210KKX7R8BB226
    return request({
        url: `/deehow-BOM-Web/component/sycn`,
        method: 'get',
    })
}

/**
 * 获取完全匹配
 *
 */
export function readMatchAll(params) {
    const param = JSON.stringify(params);
    return request({
        url: `/deehow-BOM-Web/match-all`,
        method: 'post',
        data: param,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 同步物料
 */
export function syncPlmData(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/plm/v1/sync-data`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json'
        },
        timeout: 100 * 1000,
    })
}

/**
 * 同步人员
 */
export function syncUsers(params) {
    return request({
        url: `/api/v1/sync-users`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 元器件快速搜索or高级搜索
 */
export function componentSearch(params) {
    const data = params;
    return request({
        url: '/api/v1/componentGoods/search',
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data,
    })
}

/**
 * 获取模块分类
 */
export function getModule(params) {
    const param = JSON.stringify(params);
    return request({
        url: `api/v1/model/read/list`,
        method: 'get',
        params:param,
        headers:{
            'Content-Type': 'application/json'
        }
    })
}

/**
 * 查询制造商供应商类目
 */
export function getManufacturersAndSuppliersCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/manufacturersAndSuppliers/category/read/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 添加/修改制造商供应商类目
 */
export function addManufacturersAndSuppliersCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/manufacturersAndSuppliers/category/update`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 删除制造商供应商类目
 */
export function deleteManufacturersAndSuppliersCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/manufacturersAndSuppliers/category/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

//恢复历史记录
export function recoverHistory(id) {
    return request({
        url: `api/v1/recover/component/${id}`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 器件对比
 */
export function componentGoodsCompare(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/componentGoods/compare`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 器件对比报表
 */
export function goodsCompareReport(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/componentGoods/compare/report`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    })
}

/**
 * 分类器件对比
 */
export function componentGoodsCompareCategroy(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/componentGoods/compareCategory`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 分类器件对比报表
 */
export function componentGoodsCompareReport(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/componentGoods/compareCategory/report`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob',
    })
}

//获取元器件笔记
export function getNotesList(params) {
    return request({
        url: `/api/v1/component-note/${params.goodsId}`,
        method: 'get',
        params: {
            size: params.size,
            num: params.num,
        },
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

//添加或修改元器件笔记
export function addNote(data) {
    return request({
        url: `/api/v1/component-note`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

//删除元器件笔记
export function deleNote(id) {
    return request({
        url: `/api/v1/component-note/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 查询优选目录
 */
export function postPreferedCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/prefered-category/read/list`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 查询优选目录
 */
export function addPreferedCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/prefered-category/update`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 删除优选目录
 */
export function deletePreferedCategory(params) {
    let data = JSON.stringify(params);
    return request({
        url: `/api/v1/prefered-category/delete`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
}


//获得BOM安装配置分隔符和连续符
export function getBOMSetting() {

    return request({
        url: '/deehow-BOM-Web/bom-card-setting',
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    });
}

//上传BOM安装配置分隔符和连续符
export function setBOMSetting(data) {
    let obj = JSON.stringify(data);
    return request({
        url: '/deehow-BOM-Web/bom-card-setting',
        method: 'post',
        data: obj,
        headers: {
            'content-type': 'application/json'
        }
    });
}

// 统计分析
export function getBillStatistics(id) {
    return request({
        url: `/deehow-BOM-Web/bill-statistics/${id}`,
        method: 'get',
        headers: {
            'content-type': 'application/json'
        }
    });
}


/**
 * 文档转换
 */
export function convertDocument(params) {
    return request({
        url: `/api/v1/convert-document`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 1000 * 60 * 2,
    })
}

/**
 * 文档转换
 */
export function convertDocumentPreview(params) {
    return request({
        url: `/api/v1/convert-document/new`,
        method: 'post',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 1000 * 60 * 2,
    })
}

/**
 * 元器件库共有属性筛选
 */
export function compontentsGroupCount(params) {
    // 参数 property
    return request({
        url: `/api/v1/componentGoods/groupCount`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 元器件库特殊属性筛选
 */
export function compontentsGroupCountSpecial(params) {
    return request({
        url: `/api/v1/componentGoods/groupCountSpecial`,
        method: 'post',
        data: params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 元器件库特殊属性筛选
 */
export function compontentsExportReport(params) {
    return request({
        url: `/api/v1/component/action/record/export`,
        method: 'get',
        params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 元器件库特殊属性筛选
 */
export function deleteComponentGoodsRecycle(params) {
    return request({
        url: `/api/v1/componentGoodsRecycle/delete`,
        method: 'delete',
        params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 错误反馈查询接口
 */
export function queryErrorFeedbackData(params) {
    return request({
        url: `/api/v1/componentGoodsFeedback/search/page`,
        method: 'POST',
        data: params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 错误反馈删除接口
 */
export function delErrorFeedbackData(params) {
    const data = JSON.stringify(params);
    return request({
        url: '/api/v1/componentGoodsFeedback/delete',
        method: 'post',
        data,
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 使用评价查询接口
 */
export function queryUseEvaluationData(params) {
    return request({
        url: `/api/v1/componentGoodsComment/search/page`,
        method: 'POST',
        data: params,
        headers: {
            'content-type': 'application/json'
        },
    })
}

/**
 * 删除评价
 */
export function delUseEvaluationData(params) {
    const data = JSON.stringify(params);
    return request({
        url: '/api/v1/componentGoodsComment/delete',
        method: 'post',
        data,
        headers: {
            'content-type': 'application/json'
        }
    })
}

/**
 * 个人笔记查询接口
 */
export function queryPersonalNotesData(params) {
    return request({
        url: `/api/v1/component-note/search/page`,
        method: 'POST',
        data: params,
        headers: {
            'content-type': 'application/json'
        },
        timeout: 100 * 1000,
    })
}

/**
 * 删除个人笔记
 */
export function delPersonalNotesData(id) {
    return request({
        url: `/api/v1/component-note/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

/**
 * 反馈及评价导出
 */
export function exportFeedbackaEvaluation(params) {
    return request({
        url: `/api/v1/component/action/record/exportByTime`,
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'blob'
    })
}

/**
 * 用器件中的制造商更新手册中的制造商(单条)
 */
export function updateManufacturer(id) {
    return request({
        url: `/api/v1/updateManufacturer/${id}`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 用器件中的制造商更新全部手册中的制造商(全部)
 */
export function updateManufacturerAll() {
    return request({
        url: `/api/v1/updateAllManufacturer`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 1000 * 30,
    })
}

/**
 * 产品追溯
 */
export function refProductList(par) {
    return request({
        url: `/api/v1/product-ref/read/list`,
        method: 'POST',
        data: JSON.stringify(par),
        headers: {
            'Content-Type': 'application/json',
        },
        timeout: 1000 * 20,
    })
}

/**
 * 数据手册记录状态更新接口
 */
export function updateManualStatus(par) {
    return request({
        url: `/api/v1/elib/manual/anon/status/update`,
        method: 'POST',
        data: JSON.stringify(par),
        headers: {
            'Content-Type': 'application/json',
        },
    })
}


/**
 * 元器件库-涉及追溯导出
 */
export function exportDesignTraceability(queryInfo) {
    return request({
        url: `/api/v1/product-ref/export/designTraceability`,
        method: 'POST',
        data: JSON.stringify(queryInfo),
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 板卡BOM查询节点的可见范围
 */
export function queryNodeVisibilityInfo(parentCategoryId) {
    return request({
        url: `/deehow-BOM-Web/bom-category-view-range/${parentCategoryId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 分页查询可见范围为部门时的数据
 */
export function queryBomCategoryDept(queryInfo) {
    return request({
        url: `/deehow-BOM-Web/bom-category-dept/page`,
        method: 'POST',
        data: queryInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 分页查询可见范围为项目时的人员
 */
export function queryBomCategoryUser(queryInfo) {
    return request({
        url: `/deehow-BOM-Web/bom-category-user/page`,
        method: 'POST',
        data: queryInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 分类可见范围为部门和项目组的时候详情查询
 */
export function queryBomCategorySel(queryInfo) {
    return request({
        url: `/deehow-BOM-Web/bom-category/dept-or-user/detail`,
        method: 'POST',
        data: queryInfo,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 查询模块电路元器件的对应数据的BOM清单数据
 */
export function querySymbolLibBomData(params) {
    return request({
        url: `/api/v1/symbol-lib-bom/list`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 模块电路设计追溯查询接口
 */
export function querySymbolLibBomDesignTraceability(params) {
    return request({
        url: `/api/v1/symbol-lib-bom/design/traceability`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 查询登录用户列表展示字段配置接口
 */
export function getSelfArrangement(params) {
    return request({
        url: `/api/v1/componentPublicPropertyConfSelf/self/arrangement`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 个人设置左侧详情字段列表获取接口
 */
export function getSelfDetailListField(params) {
    return request({
        url: `/api/v1/componentPublicPropertyConfSelf/self/detail/list`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 新增/修改个人列表展示字段配置接口
 */
export function updateSelfArrangement(data) {
    return request({
        url: `/api/v1/componentPublicPropertyConfSelf/self/arrangement/update`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

/**
 * 查询个人设置的列表展示详情接口
 */
export function getSelfDisplayList(params) {
    return request({
        url: `/api/v1/componentPublicPropertyConfSelf/self/list/display`,
        method: 'get',
        params,
        headers: {
            'Content-Type': 'application/json',
        },
    })
}
